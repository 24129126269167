<template>
  <div>
    <div class="pb-1 d-flex align-center">
      <div class="w-70 pr-3">
        <input-qr-scan-model v-model="filters.keywords" :label="$t('labels.mobile_material_search')"
          @keyupEnter="getList"></input-qr-scan-model>
      </div>
      <div class="w-30">
        <input-qr-scan-model v-model="filters.storage_location" :label="$t('labels.position')"
          @keyupEnter="getList"></input-qr-scan-model>
      </div>
    </div>

    <v-list dense style="height: calc(100vh - 170px)" class="overflow-x-hidden overflow-y-auto">
      <div v-for="(item, index) in items" :key="`${item.id}_${index}`">
        <v-list-item>
          <v-list-item-content class="pb-0">
            <v-list-item-title class="white-space-normal">
              {{ item.material_warehouse_code }} - {{ item.company_name }}
            </v-list-item-title>
            <v-list-item-title class="white-space-normal">{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle class="font-weight-regular white-space-normal">
              <span class="">{{ item.description }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-regular">
              <div class="d-flex align-center">{{ $t('labels.sku') }}: {{ item.sku }}</div>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-regular">
              {{ $t('labels.barcode') }}:
              <span class="font-weight-medium error--text">{{ item.code }}</span> -
              {{ $t('labels.size') }}: <span class="black--text font-weight-medium">{{ item.size }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-regular">
              {{ $t('labels.position') }}: <span class="black--text">{{ item.storage_location }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-regular my-2">
              <div class="d-flex">
                <div class="w-50">
                  <span style="width: 65px;" class="d-inline-block">{{ $t('labels.receiving') }}:</span>
                  <span class="black--text">{{ formatNumber(item.receiving) }}</span>
                </div>
                <div class="w-50">
                  <span style="width: 65px;" class="d-inline-block">{{ $t('labels.wait_pickup') }}:</span>
                  <span class="black--text">{{ formatNumber(item.wait_pickup) }}</span>
                </div>
              </div>
              <div class="d-flex">
                <div class="w-50">
                  <span style="width: 65px;" class="d-inline-block">{{ $t('labels.pickedup') }}:</span>
                  <span class="black--text">{{ formatNumber(item.pickedup) }}</span>
                </div>
                <div class="w-50">
                  <span style="width: 65px;" class="d-inline-block">{{ $t('labels.available') }}:</span>
                  <span class="black--text">{{ formatNumber(item.available) }}</span>
                </div>
              </div>
            </v-list-item-subtitle>

          </v-list-item-content>

          <v-list-item-avatar rounded="0">
            <ImageViewer :url="item.image || require('@/assets/common/no-image.jpg')" />
          </v-list-item-avatar>
        </v-list-item>
        <v-divider v-if="index < (items.length - 1)"></v-divider>
      </div>
    </v-list>

    <v-divider></v-divider>
    <div>
      <v-pagination v-model="page" :length="totalPage" :total-visible="4"></v-pagination>
    </div>
  </div>
</template>

<script>
import list from "@/components/material/mixins/list";

export default {
  name: "ListMobile",
  components: {
    ImageViewer: () => import('@/components/common/ImageViewer'),
  },
  mixins: [list]
}
</script>

<style scoped></style>
